import React, { FC } from "react"
import { NextSeo, NextSeoProps } from "next-seo"
import Image from "next/image"
import { Text } from "@components/ui/typograhpy/Text"
import Link from "next/link"
import * as styles from "./SplitScreenPage.styles"
import { Toasts } from "@components/Toasts"
import { Logo } from "@components/Logo"

type SplitScreenPageProps = NextSeoProps & {
  footerText?: any
  variant?: "default" | "error"
}

export const SplitScreenPage: FC<SplitScreenPageProps> = ({
  children,
  footerText,
  variant = "default",
  ...rest
}) => {
  return (
    <>
      <NextSeo {...rest} />
      <Link href="/" passHref>
        <a className={styles.logo}>
          <Logo variant="full" />
        </a>
      </Link>
      <div className={styles.grid} data-variant={variant}>
        <div className={styles.contentContainer}>
          {children}
          <Text variant="micro" color="shade500" className={styles.footer}>
            {footerText}
          </Text>
        </div>
        <div className={styles.imageContainer} data-variant={variant}>
          <Image
            src={getImageSrc(variant)}
            alt="example picture with before and after"
            layout="fill"
            objectFit="cover"
            className={styles.image}
            priority
          />
        </div>
      </div>
      <Toasts />
    </>
  )
}

function getImageSrc(variant: SplitScreenPageProps["variant"]) {
  if (variant === "default") {
    return "/assets/auth-page-picture.jpg"
  }
  if (variant === "error") {
    return "/assets/error-splitpage.jpg"
  }
  return ""
}
