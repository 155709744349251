import { css } from "@linaria/core";
import { rgba, rem } from "polished";
import { parse, theme } from "@config/theme";
export const grid = parse({
  display: "grid",
  p: "24"
}, "gv98twp");
export const contentContainer = parse({
  display: "flex",
  width: "100%",
  height: "auto"
}, "c1ksd7sg");
export const footer = parse({
  position: "absolute",
  mb: "24"
}, "f122vq7s");
export const logo = parse({
  position: "absolute",
  mt: "16"
}, "l1y40011");
export const imageContainer = parse({
  display: {
    _: "none",
    medium: "block"
  },
  position: "relative"
}, "i14bd3zh");
export const image = "i1c9qwju";

require("./SplitScreenPage.styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SplitScreenPage.styles.ts");